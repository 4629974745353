import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { Accordion, Toolbar, ContextMenu, Breadcrumb, Carousel, Tab, TreeView, Sidebar, Menu, AppBar, Stepper } from '@syncfusion/ej2-navigations';
const _c0 = ["header"];
const _c1 = ["content"];
const _c2 = ["headerTemplate"];
const _c3 = ["itemTemplate"];
const _c4 = [[["div"]]];
const _c5 = ["div"];
const _c6 = ["template"];
const _c7 = ["separatorTemplate"];
const _c8 = ["indicatorsTemplate"];
const _c9 = ["nextButtonTemplate"];
const _c10 = ["previousButtonTemplate"];
const _c11 = ["playButtonTemplate"];
const _c12 = ["headerText"];
const _c13 = ["nodeTemplate"];
const _c14 = ["*"];
const _c15 = ["tooltipTemplate"];
const _c16 = [[["nav"]]];
const _c17 = ["nav"];
export * from '@syncfusion/ej2-navigations';
import { CommonModule } from '@angular/common';
let input$6 = ['content', 'cssClass', 'disabled', 'expanded', 'header', 'iconCss', 'id', 'visible'];
let outputs$h = [];
/**
 * 'e-accordionitem' directive represent a item of the Angular Accordion.
 * It must be contained in a Accordion component(`ejs-accordion`).
 * ```html
 * <ejs-accordion>
 *   <e-accordionitems>
 *    <e-accordionitem header='Header1'></e-accordionitem>
 *    <e-accordionitem header='Header2' content='Content2'></e-accordionitem>
 *   </e-accordionitems>
 * </ejs-accordion>
 * ```
 */
class AccordionItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$h);
    this.directivePropList = input$6;
  }
}
AccordionItemDirective.ɵfac = function AccordionItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
AccordionItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AccordionItemDirective,
  selectors: [["e-accordionitem"]],
  contentQueries: function AccordionItemDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    content: "content",
    cssClass: "cssClass",
    disabled: "disabled",
    expanded: "expanded",
    header: "header",
    iconCss: "iconCss",
    id: "id",
    visible: "visible"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], AccordionItemDirective.prototype, "header", void 0);
__decorate([Template()], AccordionItemDirective.prototype, "content", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-accordionitems>e-accordionitem',
      inputs: input$6,
      outputs: outputs$h,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    header: [{
      type: ContentChild,
      args: ['header']
    }],
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();
/**
 * AccordionItem Array Directive
 * @private
 */
class AccordionItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
AccordionItemsDirective.ɵfac = function AccordionItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionItemsDirective)();
};
AccordionItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AccordionItemsDirective,
  selectors: [["e-accordionitems"]],
  contentQueries: function AccordionItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AccordionItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-accordion>e-accordionitems',
      queries: {
        children: new ContentChildren(AccordionItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$a = ['animation', 'dataSource', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'expandMode', 'expandedIndices', 'headerTemplate', 'height', 'itemTemplate', 'items', 'locale', 'width'];
const outputs$g = ['clicked', 'created', 'destroyed', 'expanded', 'expanding', 'expandedIndicesChange'];
const twoWays$a = ['expandedIndices'];
/**
 * Represents the Angular Accordion Component.
 * ```html
 * <ejs-accordion></ejs-accordion>
 * ```
 */
let AccordionComponent = class AccordionComponent extends Accordion {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$g);
    this.addTwoWay.call(this, twoWays$a);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.containerContext.ngAfterContentChecked(this);
  }
};
AccordionComponent.ɵfac = function AccordionComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
AccordionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AccordionComponent,
  selectors: [["ejs-accordion"]],
  contentQueries: function AccordionComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, AccordionItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    animation: "animation",
    dataSource: "dataSource",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    expandMode: "expandMode",
    expandedIndices: "expandedIndices",
    headerTemplate: "headerTemplate",
    height: "height",
    itemTemplate: "itemTemplate",
    items: "items",
    locale: "locale",
    width: "width"
  },
  outputs: {
    clicked: "clicked",
    created: "created",
    destroyed: "destroyed",
    expanded: "expanded",
    expanding: "expanding",
    expandedIndicesChange: "expandedIndicesChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c5,
  decls: 1,
  vars: 0,
  template: function AccordionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c4);
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], AccordionComponent.prototype, "headerTemplate", void 0);
__decorate([Template()], AccordionComponent.prototype, "itemTemplate", void 0);
AccordionComponent = __decorate([ComponentMixins([ComponentBase])], AccordionComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-accordion',
      inputs: inputs$a,
      outputs: outputs$g,
      template: `<ng-content select='div'></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(AccordionItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Accordion component.
 */
class AccordionModule {}
AccordionModule.ɵfac = function AccordionModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionModule)();
};
AccordionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AccordionModule
});
AccordionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [AccordionComponent, AccordionItemDirective, AccordionItemsDirective],
      exports: [AccordionComponent, AccordionItemDirective, AccordionItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Accordion component with providers.
 */
class AccordionAllModule {}
AccordionAllModule.ɵfac = function AccordionAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AccordionAllModule)();
};
AccordionAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AccordionAllModule
});
AccordionAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, AccordionModule], AccordionModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, AccordionModule],
      exports: [AccordionModule],
      providers: []
    }]
  }], null, null);
})();
let input$5 = ['align', 'cssClass', 'disabled', 'htmlAttributes', 'id', 'overflow', 'prefixIcon', 'showAlwaysInPopup', 'showTextOn', 'suffixIcon', 'tabIndex', 'template', 'text', 'tooltipText', 'type', 'visible', 'width'];
let outputs$f = ['click'];
/**
 * 'e-item' directive represent a item of the Angular Toolbar.
 * It must be contained in a Toolbar component(`ejs-toolbar`).
 * ```html
 * <ejs-toolbar>
 *   <e-items>
 *    <e-item text='Cut'></e-item>
 *    <e-item text='Copy'></e-item>
 *   </e-items>
 * </ejs-toolbar>
 * ```
 */
class ItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$f);
    this.directivePropList = input$5;
  }
}
ItemDirective.ɵfac = function ItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ItemDirective,
  selectors: [["e-item"]],
  contentQueries: function ItemDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    align: "align",
    cssClass: "cssClass",
    disabled: "disabled",
    htmlAttributes: "htmlAttributes",
    id: "id",
    overflow: "overflow",
    prefixIcon: "prefixIcon",
    showAlwaysInPopup: "showAlwaysInPopup",
    showTextOn: "showTextOn",
    suffixIcon: "suffixIcon",
    tabIndex: "tabIndex",
    template: "template",
    text: "text",
    tooltipText: "tooltipText",
    type: "type",
    visible: "visible",
    width: "width"
  },
  outputs: {
    click: "click"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ItemDirective.prototype, "template", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-items>e-item',
      inputs: input$5,
      outputs: outputs$f,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();
/**
 * Item Array Directive
 * @private
 */
class ItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
ItemsDirective.ɵfac = function ItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ItemsDirective)();
};
ItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ItemsDirective,
  selectors: [["e-items"]],
  contentQueries: function ItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-toolbar>e-items',
      queries: {
        children: new ContentChildren(ItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$9 = ['allowKeyboard', 'cssClass', 'enableCollision', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'height', 'items', 'locale', 'overflowMode', 'scrollStep', 'width'];
const outputs$e = ['beforeCreate', 'clicked', 'created', 'destroyed'];
const twoWays$9 = [''];
/**
 * Represents the Angular Toolbar Component.
 * ```html
 * <ejs-toolbar></ejs-toolbar>
 * ```
 */
let ToolbarComponent = class ToolbarComponent extends Toolbar {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$e);
    this.addTwoWay.call(this, twoWays$9);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.containerContext.ngAfterContentChecked(this);
  }
};
ToolbarComponent.ɵfac = function ToolbarComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToolbarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ToolbarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolbarComponent,
  selectors: [["ejs-toolbar"]],
  contentQueries: function ToolbarComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    allowKeyboard: "allowKeyboard",
    cssClass: "cssClass",
    enableCollision: "enableCollision",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    height: "height",
    items: "items",
    locale: "locale",
    overflowMode: "overflowMode",
    scrollStep: "scrollStep",
    width: "width"
  },
  outputs: {
    beforeCreate: "beforeCreate",
    clicked: "clicked",
    created: "created",
    destroyed: "destroyed"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c5,
  decls: 1,
  vars: 0,
  template: function ToolbarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c4);
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
ToolbarComponent = __decorate([ComponentMixins([ComponentBase])], ToolbarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-toolbar',
      inputs: inputs$9,
      outputs: outputs$e,
      template: `<ng-content select='div'></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(ItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Toolbar component.
 */
class ToolbarModule {}
ToolbarModule.ɵfac = function ToolbarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToolbarModule)();
};
ToolbarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ToolbarModule
});
ToolbarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ToolbarComponent, ItemDirective, ItemsDirective],
      exports: [ToolbarComponent, ItemDirective, ItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Toolbar component with providers.
 */
class ToolbarAllModule {}
ToolbarAllModule.ɵfac = function ToolbarAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToolbarAllModule)();
};
ToolbarAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ToolbarAllModule
});
ToolbarAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ToolbarModule], ToolbarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ToolbarModule],
      exports: [ToolbarModule],
      providers: []
    }]
  }], null, null);
})();
const inputs$8 = ['animationSettings', 'cssClass', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enableScrolling', 'fields', 'filter', 'hoverDelay', 'itemTemplate', 'items', 'locale', 'showItemOnClick', 'target', 'template'];
const outputs$d = ['beforeClose', 'beforeItemRender', 'beforeOpen', 'created', 'onClose', 'onOpen', 'select'];
const twoWays$8 = [''];
/**
 * Represents the EJ2 Angular ContextMenu Component.
 * ```html
 * <div id='target'>Right click / Touch hold to open the ContextMenu</div>
 * <ejs-contextmenu target='#target' [items]='menuItems'></ejs-contextmenu>
 * ```
 */
let ContextMenuComponent = class ContextMenuComponent extends ContextMenu {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$d);
    this.addTwoWay.call(this, twoWays$8);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
ContextMenuComponent.ɵfac = function ContextMenuComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ContextMenuComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ContextMenuComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ContextMenuComponent,
  selectors: [["ejs-contextmenu"]],
  inputs: {
    animationSettings: "animationSettings",
    cssClass: "cssClass",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableScrolling: "enableScrolling",
    fields: "fields",
    filter: "filter",
    hoverDelay: "hoverDelay",
    itemTemplate: "itemTemplate",
    items: "items",
    locale: "locale",
    showItemOnClick: "showItemOnClick",
    target: "target",
    template: "template"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeItemRender: "beforeItemRender",
    beforeOpen: "beforeOpen",
    created: "created",
    onClose: "onClose",
    onOpen: "onOpen",
    select: "select"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ContextMenuComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
ContextMenuComponent = __decorate([ComponentMixins([ComponentBase])], ContextMenuComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-contextmenu',
      inputs: inputs$8,
      outputs: outputs$d,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the ContextMenu component.
 */
class ContextMenuModule {}
ContextMenuModule.ɵfac = function ContextMenuModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ContextMenuModule)();
};
ContextMenuModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ContextMenuModule
});
ContextMenuModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ContextMenuComponent],
      exports: [ContextMenuComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the ContextMenu component with providers.
 */
class ContextMenuAllModule {}
ContextMenuAllModule.ɵfac = function ContextMenuAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ContextMenuAllModule)();
};
ContextMenuAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ContextMenuAllModule
});
ContextMenuAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ContextMenuModule], ContextMenuModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ContextMenuModule],
      exports: [ContextMenuModule],
      providers: []
    }]
  }], null, null);
})();
let input$4 = ['disabled', 'iconCss', 'id', 'text', 'url'];
let outputs$c = [];
/**
 * `e-breadcrumb-item` directive represent a item of the Angular Breadcrumb.
 * It must be contained in a Breadcrumb component(`ejs-breadcrumb`).
 * ```html
 * <ejs-breadcrumb>
 *   <e-breadcrumb-items>
 *    <e-breadcrumb-item text='Home' url='/'></e-breadcrumb-item>
 *    <e-breadcrumb-item text='Index' url='./index'></e-breadcrumb-item>
 *   </e-breadcrumb-items>
 * </ejs-breadcrumb>
 * ```
 */
class BreadcrumbItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$c);
    this.directivePropList = input$4;
  }
}
BreadcrumbItemDirective.ɵfac = function BreadcrumbItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadcrumbItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
BreadcrumbItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BreadcrumbItemDirective,
  selectors: [["e-breadcrumb-item"]],
  inputs: {
    disabled: "disabled",
    iconCss: "iconCss",
    id: "id",
    text: "text",
    url: "url"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbItemDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-breadcrumb>e-breadcrumb-items>e-breadcrumb-item',
      inputs: input$4,
      outputs: outputs$c,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * BreadcrumbItem Array Directive
 * @private
 */
class BreadcrumbItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
BreadcrumbItemsDirective.ɵfac = function BreadcrumbItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadcrumbItemsDirective)();
};
BreadcrumbItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BreadcrumbItemsDirective,
  selectors: [["e-breadcrumb-items"]],
  contentQueries: function BreadcrumbItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, BreadcrumbItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-breadcrumb>e-breadcrumb-items',
      queries: {
        children: new ContentChildren(BreadcrumbItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$7 = ['activeItem', 'cssClass', 'disabled', 'enableActiveItemNavigation', 'enableNavigation', 'enablePersistence', 'enableRtl', 'itemTemplate', 'items', 'locale', 'maxItems', 'overflowMode', 'separatorTemplate', 'url'];
const outputs$b = ['beforeItemRender', 'created', 'itemClick', 'activeItemChange'];
const twoWays$7 = ['activeItem'];
/**
 * Represents the EJ2 Angular Breadcrumb Component.
 * ```html
 * <ejs-breadcrumb [items]='breadcrumbItems'></ejs-breadcrumb>
 * ```
 */
let BreadcrumbComponent = class BreadcrumbComponent extends Breadcrumb {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$b);
    this.addTwoWay.call(this, twoWays$7);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.context.ngAfterContentChecked(this);
  }
};
BreadcrumbComponent.ɵfac = function BreadcrumbComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadcrumbComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
BreadcrumbComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BreadcrumbComponent,
  selectors: [["ejs-breadcrumb"]],
  contentQueries: function BreadcrumbComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, BreadcrumbItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.separatorTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    activeItem: "activeItem",
    cssClass: "cssClass",
    disabled: "disabled",
    enableActiveItemNavigation: "enableActiveItemNavigation",
    enableNavigation: "enableNavigation",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    itemTemplate: "itemTemplate",
    items: "items",
    locale: "locale",
    maxItems: "maxItems",
    overflowMode: "overflowMode",
    separatorTemplate: "separatorTemplate",
    url: "url"
  },
  outputs: {
    beforeItemRender: "beforeItemRender",
    created: "created",
    itemClick: "itemClick",
    activeItemChange: "activeItemChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function BreadcrumbComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], BreadcrumbComponent.prototype, "separatorTemplate", void 0);
__decorate([Template()], BreadcrumbComponent.prototype, "itemTemplate", void 0);
BreadcrumbComponent = __decorate([ComponentMixins([ComponentBase])], BreadcrumbComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-breadcrumb',
      inputs: inputs$7,
      outputs: outputs$b,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(BreadcrumbItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    separatorTemplate: [{
      type: ContentChild,
      args: ['separatorTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Breadcrumb component.
 */
class BreadcrumbModule {}
BreadcrumbModule.ɵfac = function BreadcrumbModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadcrumbModule)();
};
BreadcrumbModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BreadcrumbModule
});
BreadcrumbModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [BreadcrumbComponent, BreadcrumbItemDirective, BreadcrumbItemsDirective],
      exports: [BreadcrumbComponent, BreadcrumbItemDirective, BreadcrumbItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Breadcrumb component with providers.
 */
class BreadcrumbAllModule {}
BreadcrumbAllModule.ɵfac = function BreadcrumbAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadcrumbAllModule)();
};
BreadcrumbAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BreadcrumbAllModule
});
BreadcrumbAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, BreadcrumbModule], BreadcrumbModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, BreadcrumbModule],
      exports: [BreadcrumbModule],
      providers: []
    }]
  }], null, null);
})();
let input$3 = ['cssClass', 'htmlAttributes', 'interval', 'template'];
let outputs$a = [];
/**
 * `e-carousel-item` directive represent a item of the Angular Carousel.
 * It must be contained in a Carousel component(`ejs-carousel`).
 * ```html
 * <ejs-carousel>
 *   <e-carousel-items>
 *    <e-carousel-item template='#item1'></e-carousel-item>
 *    <e-carousel-item template='#item2'></e-carousel-item>
 *   </e-carousel-items>
 * </ejs-carousel>
 * ```
 */
class CarouselItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$a);
    this.directivePropList = input$3;
  }
}
CarouselItemDirective.ɵfac = function CarouselItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CarouselItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
CarouselItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CarouselItemDirective,
  selectors: [["e-carousel-item"]],
  contentQueries: function CarouselItemDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    cssClass: "cssClass",
    htmlAttributes: "htmlAttributes",
    interval: "interval",
    template: "template"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], CarouselItemDirective.prototype, "template", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselItemDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-carousel>e-carousel-items>e-carousel-item',
      inputs: input$3,
      outputs: outputs$a,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();
/**
 * CarouselItem Array Directive
 * @private
 */
class CarouselItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
CarouselItemsDirective.ɵfac = function CarouselItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CarouselItemsDirective)();
};
CarouselItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CarouselItemsDirective,
  selectors: [["e-carousel-items"]],
  contentQueries: function CarouselItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, CarouselItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-carousel>e-carousel-items',
      queries: {
        children: new ContentChildren(CarouselItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$6 = ['allowKeyboardInteraction', 'animationEffect', 'autoPlay', 'buttonsVisibility', 'cssClass', 'dataSource', 'enablePersistence', 'enableRtl', 'enableTouchSwipe', 'height', 'htmlAttributes', 'indicatorsTemplate', 'indicatorsType', 'interval', 'itemTemplate', 'items', 'locale', 'loop', 'nextButtonTemplate', 'partialVisible', 'pauseOnHover', 'playButtonTemplate', 'previousButtonTemplate', 'selectedIndex', 'showIndicators', 'showPlayButton', 'swipeMode', 'width'];
const outputs$9 = ['slideChanged', 'slideChanging', 'selectedIndexChange'];
const twoWays$6 = ['selectedIndex'];
/**
 * Represents the EJ2 Angular Carousel Component.
 * ```html
 * <ejs-carousel [items]='carouselItems'></ejs-carousel>
 * ```
 */
let CarouselComponent = class CarouselComponent extends Carousel {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$9);
    this.addTwoWay.call(this, twoWays$6);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.context.ngAfterContentChecked(this);
  }
};
CarouselComponent.ɵfac = function CarouselComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CarouselComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
CarouselComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CarouselComponent,
  selectors: [["ejs-carousel"]],
  contentQueries: function CarouselComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
      i0.ɵɵcontentQuery(dirIndex, _c9, 5);
      i0.ɵɵcontentQuery(dirIndex, _c10, 5);
      i0.ɵɵcontentQuery(dirIndex, _c11, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, CarouselItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.indicatorsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nextButtonTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.previousButtonTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.playButtonTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    allowKeyboardInteraction: "allowKeyboardInteraction",
    animationEffect: "animationEffect",
    autoPlay: "autoPlay",
    buttonsVisibility: "buttonsVisibility",
    cssClass: "cssClass",
    dataSource: "dataSource",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableTouchSwipe: "enableTouchSwipe",
    height: "height",
    htmlAttributes: "htmlAttributes",
    indicatorsTemplate: "indicatorsTemplate",
    indicatorsType: "indicatorsType",
    interval: "interval",
    itemTemplate: "itemTemplate",
    items: "items",
    locale: "locale",
    loop: "loop",
    nextButtonTemplate: "nextButtonTemplate",
    partialVisible: "partialVisible",
    pauseOnHover: "pauseOnHover",
    playButtonTemplate: "playButtonTemplate",
    previousButtonTemplate: "previousButtonTemplate",
    selectedIndex: "selectedIndex",
    showIndicators: "showIndicators",
    showPlayButton: "showPlayButton",
    swipeMode: "swipeMode",
    width: "width"
  },
  outputs: {
    slideChanged: "slideChanged",
    slideChanging: "slideChanging",
    selectedIndexChange: "selectedIndexChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CarouselComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], CarouselComponent.prototype, "indicatorsTemplate", void 0);
__decorate([Template()], CarouselComponent.prototype, "nextButtonTemplate", void 0);
__decorate([Template()], CarouselComponent.prototype, "previousButtonTemplate", void 0);
__decorate([Template()], CarouselComponent.prototype, "playButtonTemplate", void 0);
__decorate([Template()], CarouselComponent.prototype, "itemTemplate", void 0);
CarouselComponent = __decorate([ComponentMixins([ComponentBase])], CarouselComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-carousel',
      inputs: inputs$6,
      outputs: outputs$9,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(CarouselItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    indicatorsTemplate: [{
      type: ContentChild,
      args: ['indicatorsTemplate']
    }],
    nextButtonTemplate: [{
      type: ContentChild,
      args: ['nextButtonTemplate']
    }],
    previousButtonTemplate: [{
      type: ContentChild,
      args: ['previousButtonTemplate']
    }],
    playButtonTemplate: [{
      type: ContentChild,
      args: ['playButtonTemplate']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Carousel component.
 */
class CarouselModule {}
CarouselModule.ɵfac = function CarouselModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CarouselModule)();
};
CarouselModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CarouselModule
});
CarouselModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [CarouselComponent, CarouselItemDirective, CarouselItemsDirective],
      exports: [CarouselComponent, CarouselItemDirective, CarouselItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Carousel component with providers.
 */
class CarouselAllModule {}
CarouselAllModule.ɵfac = function CarouselAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CarouselAllModule)();
};
CarouselAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CarouselAllModule
});
CarouselAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, CarouselModule], CarouselModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CarouselAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, CarouselModule],
      exports: [CarouselModule],
      providers: []
    }]
  }], null, null);
})();
let input$2 = ['content', 'cssClass', 'disabled', 'header', 'headerTemplate', 'id', 'tabIndex', 'visible'];
let outputs$8 = [];
/**
 * 'e-tabitem' directive represent a item of the Angular Tab.
 * It must be contained in a Tab component(`ejs-tab`).
 * ```html
 * <ejs-tab>
 *  <e-tabitems>
 *   <e-tabitem [header]='Header 1' [content]='Content 1'></e-tabitem>
 *   <e-tabitem [header]='Header 2' [content]='Content 2'></e-tabitem>
 *  <e-tabitems>
 * </ejs-tab>
 * ```
 */
class TabItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$8);
    this.directivePropList = input$2;
  }
}
TabItemDirective.ɵfac = function TabItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TabItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
TabItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TabItemDirective,
  selectors: [["e-tabitem"]],
  contentQueries: function TabItemDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c12, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header_text = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
    }
  },
  inputs: {
    content: "content",
    cssClass: "cssClass",
    disabled: "disabled",
    header: "header",
    headerTemplate: "headerTemplate",
    id: "id",
    tabIndex: "tabIndex",
    visible: "visible"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], TabItemDirective.prototype, "content", void 0);
__decorate([Template()], TabItemDirective.prototype, "header_text", void 0);
__decorate([Template()], TabItemDirective.prototype, "headerTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-tabitems>e-tabitem',
      inputs: input$2,
      outputs: outputs$8,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    content: [{
      type: ContentChild,
      args: ['content']
    }],
    header_text: [{
      type: ContentChild,
      args: ['headerText']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }]
  });
})();
/**
 * TabItem Array Directive
 * @private
 */
class TabItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
TabItemsDirective.ɵfac = function TabItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TabItemsDirective)();
};
TabItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TabItemsDirective,
  selectors: [["e-tabitems"]],
  contentQueries: function TabItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TabItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-tab>e-tabitems',
      queries: {
        children: new ContentChildren(TabItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$5 = ['allowDragAndDrop', 'animation', 'clearTemplates', 'cssClass', 'dragArea', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'headerPlacement', 'height', 'heightAdjustMode', 'items', 'loadOn', 'locale', 'overflowMode', 'reorderActiveTab', 'scrollStep', 'selectedItem', 'showCloseButton', 'swipeMode', 'width'];
const outputs$7 = ['added', 'adding', 'created', 'destroyed', 'dragged', 'dragging', 'onDragStart', 'removed', 'removing', 'selected', 'selecting'];
const twoWays$5 = [''];
/**
 * Represents the Angular Tab Component.
 * ```html
 * <ejs-tab></ejs-tab>
 * ```
 */
let TabComponent = class TabComponent extends Tab {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$7);
    this.addTwoWay.call(this, twoWays$5);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.containerContext.ngAfterContentChecked(this);
  }
};
TabComponent.ɵfac = function TabComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TabComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
TabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TabComponent,
  selectors: [["ejs-tab"]],
  contentQueries: function TabComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TabItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    allowDragAndDrop: "allowDragAndDrop",
    animation: "animation",
    clearTemplates: "clearTemplates",
    cssClass: "cssClass",
    dragArea: "dragArea",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    headerPlacement: "headerPlacement",
    height: "height",
    heightAdjustMode: "heightAdjustMode",
    items: "items",
    loadOn: "loadOn",
    locale: "locale",
    overflowMode: "overflowMode",
    reorderActiveTab: "reorderActiveTab",
    scrollStep: "scrollStep",
    selectedItem: "selectedItem",
    showCloseButton: "showCloseButton",
    swipeMode: "swipeMode",
    width: "width"
  },
  outputs: {
    added: "added",
    adding: "adding",
    created: "created",
    destroyed: "destroyed",
    dragged: "dragged",
    dragging: "dragging",
    onDragStart: "onDragStart",
    removed: "removed",
    removing: "removing",
    selected: "selected",
    selecting: "selecting"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c5,
  decls: 1,
  vars: 0,
  template: function TabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c4);
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
TabComponent = __decorate([ComponentMixins([ComponentBase])], TabComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-tab',
      inputs: inputs$5,
      outputs: outputs$7,
      template: `<ng-content select='div'></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(TabItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Tab component.
 */
class TabModule {}
TabModule.ɵfac = function TabModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TabModule)();
};
TabModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TabModule
});
TabModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TabComponent, TabItemDirective, TabItemsDirective],
      exports: [TabComponent, TabItemDirective, TabItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Tab component with providers.
 */
class TabAllModule {}
TabAllModule.ɵfac = function TabAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TabAllModule)();
};
TabAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TabAllModule
});
TabAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, TabModule], TabModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TabModule],
      exports: [TabModule],
      providers: []
    }]
  }], null, null);
})();
const inputs$4 = ['allowDragAndDrop', 'allowEditing', 'allowMultiSelection', 'allowTextWrap', 'animation', 'autoCheck', 'checkDisabledChildren', 'checkedNodes', 'cssClass', 'disabled', 'dragArea', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'expandOn', 'expandedNodes', 'fields', 'fullRowNavigable', 'fullRowSelect', 'loadOnDemand', 'locale', 'nodeTemplate', 'selectedNodes', 'showCheckBox', 'sortOrder'];
const outputs$6 = ['actionFailure', 'created', 'dataBound', 'dataSourceChanged', 'destroyed', 'drawNode', 'keyPress', 'nodeChecked', 'nodeChecking', 'nodeClicked', 'nodeCollapsed', 'nodeCollapsing', 'nodeDragStart', 'nodeDragStop', 'nodeDragging', 'nodeDropped', 'nodeEdited', 'nodeEditing', 'nodeExpanded', 'nodeExpanding', 'nodeSelected', 'nodeSelecting'];
const twoWays$4 = [''];
/**
 * TreeView component is used to represent the hierarchical data in tree like structure with advanced functions to perform edit, drag and drop, selection with check-box and more.
 * ```html
 * <ej-treeview allowDragAndDrop='true'></ej-treeview>
 * ```
 */
let TreeViewComponent = class TreeViewComponent extends TreeView {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$6);
    this.addTwoWay.call(this, twoWays$4);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
TreeViewComponent.ɵfac = function TreeViewComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TreeViewComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
TreeViewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TreeViewComponent,
  selectors: [["ejs-treeview"]],
  contentQueries: function TreeViewComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c13, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nodeTemplate = _t.first);
    }
  },
  inputs: {
    allowDragAndDrop: "allowDragAndDrop",
    allowEditing: "allowEditing",
    allowMultiSelection: "allowMultiSelection",
    allowTextWrap: "allowTextWrap",
    animation: "animation",
    autoCheck: "autoCheck",
    checkDisabledChildren: "checkDisabledChildren",
    checkedNodes: "checkedNodes",
    cssClass: "cssClass",
    disabled: "disabled",
    dragArea: "dragArea",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    expandOn: "expandOn",
    expandedNodes: "expandedNodes",
    fields: "fields",
    fullRowNavigable: "fullRowNavigable",
    fullRowSelect: "fullRowSelect",
    loadOnDemand: "loadOnDemand",
    locale: "locale",
    nodeTemplate: "nodeTemplate",
    selectedNodes: "selectedNodes",
    showCheckBox: "showCheckBox",
    sortOrder: "sortOrder"
  },
  outputs: {
    actionFailure: "actionFailure",
    created: "created",
    dataBound: "dataBound",
    dataSourceChanged: "dataSourceChanged",
    destroyed: "destroyed",
    drawNode: "drawNode",
    keyPress: "keyPress",
    nodeChecked: "nodeChecked",
    nodeChecking: "nodeChecking",
    nodeClicked: "nodeClicked",
    nodeCollapsed: "nodeCollapsed",
    nodeCollapsing: "nodeCollapsing",
    nodeDragStart: "nodeDragStart",
    nodeDragStop: "nodeDragStop",
    nodeDragging: "nodeDragging",
    nodeDropped: "nodeDropped",
    nodeEdited: "nodeEdited",
    nodeEditing: "nodeEditing",
    nodeExpanded: "nodeExpanded",
    nodeExpanding: "nodeExpanding",
    nodeSelected: "nodeSelected",
    nodeSelecting: "nodeSelecting"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function TreeViewComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], TreeViewComponent.prototype, "nodeTemplate", void 0);
TreeViewComponent = __decorate([ComponentMixins([ComponentBase])], TreeViewComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeViewComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-treeview',
      inputs: inputs$4,
      outputs: outputs$6,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    nodeTemplate: [{
      type: ContentChild,
      args: ['nodeTemplate']
    }]
  });
})();

/**
 * NgModule definition for the TreeView component.
 */
class TreeViewModule {}
TreeViewModule.ɵfac = function TreeViewModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TreeViewModule)();
};
TreeViewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TreeViewModule
});
TreeViewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeViewModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TreeViewComponent],
      exports: [TreeViewComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the TreeView component with providers.
 */
class TreeViewAllModule {}
TreeViewAllModule.ɵfac = function TreeViewAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TreeViewAllModule)();
};
TreeViewAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TreeViewAllModule
});
TreeViewAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, TreeViewModule], TreeViewModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeViewAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TreeViewModule],
      exports: [TreeViewModule],
      providers: []
    }]
  }], null, null);
})();
const inputs$3 = ['animate', 'closeOnDocumentClick', 'dockSize', 'enableDock', 'enableGestures', 'enablePersistence', 'enableRtl', 'height', 'isOpen', 'locale', 'mediaQuery', 'position', 'showBackdrop', 'target', 'type', 'width', 'zIndex'];
const outputs$5 = ['change', 'close', 'created', 'destroyed', 'open', 'isOpenChange'];
const twoWays$3 = ['isOpen'];
/**
 * Represents the Essential JS 2 Angular Sidebar Component.
 * ```html
 * <ejs-sidebar></ejs-sidebar>
 * ```
 */
let SidebarComponent = class SidebarComponent extends Sidebar {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$5);
    this.addTwoWay.call(this, twoWays$3);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
SidebarComponent.ɵfac = function SidebarComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SidebarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
SidebarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SidebarComponent,
  selectors: [["ejs-sidebar"]],
  inputs: {
    animate: "animate",
    closeOnDocumentClick: "closeOnDocumentClick",
    dockSize: "dockSize",
    enableDock: "enableDock",
    enableGestures: "enableGestures",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    height: "height",
    isOpen: "isOpen",
    locale: "locale",
    mediaQuery: "mediaQuery",
    position: "position",
    showBackdrop: "showBackdrop",
    target: "target",
    type: "type",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    change: "change",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    open: "open",
    isOpenChange: "isOpenChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c14,
  decls: 1,
  vars: 0,
  template: function SidebarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
SidebarComponent = __decorate([ComponentMixins([ComponentBase])], SidebarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-sidebar',
      inputs: inputs$3,
      outputs: outputs$5,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Sidebar component.
 */
class SidebarModule {}
SidebarModule.ɵfac = function SidebarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SidebarModule)();
};
SidebarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SidebarModule
});
SidebarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SidebarComponent],
      exports: [SidebarComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Sidebar component with providers.
 */
class SidebarAllModule {}
SidebarAllModule.ɵfac = function SidebarAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SidebarAllModule)();
};
SidebarAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SidebarAllModule
});
SidebarAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SidebarModule], SidebarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SidebarModule],
      exports: [SidebarModule],
      providers: []
    }]
  }], null, null);
})();
let input$1 = ['htmlAttributes', 'iconCss', 'id', 'items', 'separator', 'text', 'url'];
let outputs$4 = [];
class MenuItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$1;
  }
}
MenuItemDirective.ɵfac = function MenuItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
MenuItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuItemDirective,
  selectors: [["e-menu-item"]],
  inputs: {
    htmlAttributes: "htmlAttributes",
    iconCss: "iconCss",
    id: "id",
    items: "items",
    separator: "separator",
    text: "text",
    url: "url"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-menu>e-menu-items>e-menu-item>',
      inputs: input$1,
      outputs: outputs$4,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * MenuItem Array Directive
 * @private
 */
class MenuItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
MenuItemsDirective.ɵfac = function MenuItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuItemsDirective)();
};
MenuItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuItemsDirective,
  selectors: [["e-menu-items"]],
  contentQueries: function MenuItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, MenuItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-menu>e-menu-items',
      queries: {
        children: new ContentChildren(MenuItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$2 = ['animationSettings', 'cssClass', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enableScrolling', 'fields', 'filter', 'hamburgerMode', 'hoverDelay', 'items', 'locale', 'orientation', 'showItemOnClick', 'target', 'template', 'title'];
const outputs$3 = ['beforeClose', 'beforeItemRender', 'beforeOpen', 'created', 'onClose', 'onOpen', 'select'];
const twoWays$2 = [''];
/**
 * Represents the EJ2 Angular Menu Component.
 * ```html
 * <ejs-menu [items]='menuItems'></ejs-menu>
 * ```
 */
let MenuComponent = class MenuComponent extends Menu {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.context.ngAfterContentChecked(this);
  }
};
MenuComponent.ɵfac = function MenuComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
MenuComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MenuComponent,
  selectors: [["ejs-menu"]],
  contentQueries: function MenuComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
      i0.ɵɵcontentQuery(dirIndex, MenuItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    animationSettings: "animationSettings",
    cssClass: "cssClass",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableScrolling: "enableScrolling",
    fields: "fields",
    filter: "filter",
    hamburgerMode: "hamburgerMode",
    hoverDelay: "hoverDelay",
    items: "items",
    locale: "locale",
    orientation: "orientation",
    showItemOnClick: "showItemOnClick",
    target: "target",
    template: "template",
    title: "title"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeItemRender: "beforeItemRender",
    beforeOpen: "beforeOpen",
    created: "created",
    onClose: "onClose",
    onOpen: "onOpen",
    select: "select"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function MenuComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], MenuComponent.prototype, "template", void 0);
MenuComponent = __decorate([ComponentMixins([ComponentBase])], MenuComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-menu',
      inputs: inputs$2,
      outputs: outputs$3,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(MenuItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();

/**
 * NgModule definition for the Menu component.
 */
class MenuModule {}
MenuModule.ɵfac = function MenuModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuModule)();
};
MenuModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MenuModule
});
MenuModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [MenuComponent, MenuItemDirective, MenuItemsDirective],
      exports: [MenuComponent, MenuItemDirective, MenuItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Menu component with providers.
 */
class MenuAllModule {}
MenuAllModule.ɵfac = function MenuAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuAllModule)();
};
MenuAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MenuAllModule
});
MenuAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, MenuModule], MenuModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MenuModule],
      exports: [MenuModule],
      providers: []
    }]
  }], null, null);
})();
const inputs$1 = ['colorMode', 'cssClass', 'enablePersistence', 'enableRtl', 'htmlAttributes', 'isSticky', 'locale', 'mode', 'position'];
const outputs$2 = ['created', 'destroyed'];
const twoWays$1 = [''];
/**
 * Represents the Essential JS 2 Angular AppBar Component.
 * ```html
 * <ejs-appbar></ejs-appbar>
 * ```
 */
let AppBarComponent = class AppBarComponent extends AppBar {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
AppBarComponent.ɵfac = function AppBarComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
AppBarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AppBarComponent,
  selectors: [["ejs-appbar"]],
  inputs: {
    colorMode: "colorMode",
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    htmlAttributes: "htmlAttributes",
    isSticky: "isSticky",
    locale: "locale",
    mode: "mode",
    position: "position"
  },
  outputs: {
    created: "created",
    destroyed: "destroyed"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c14,
  decls: 1,
  vars: 0,
  template: function AppBarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
AppBarComponent = __decorate([ComponentMixins([ComponentBase])], AppBarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-appbar',
      inputs: inputs$1,
      outputs: outputs$2,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the AppBar component.
 */
class AppBarModule {}
AppBarModule.ɵfac = function AppBarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarModule)();
};
AppBarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AppBarModule
});
AppBarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [AppBarComponent],
      exports: [AppBarComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the AppBar component with providers.
 */
class AppBarAllModule {}
AppBarAllModule.ɵfac = function AppBarAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarAllModule)();
};
AppBarAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AppBarAllModule
});
AppBarAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, AppBarModule], AppBarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, AppBarModule],
      exports: [AppBarModule],
      providers: []
    }]
  }], null, null);
})();
let input = ['cssClass', 'disabled', 'iconCss', 'isValid', 'label', 'optional', 'status', 'text'];
let outputs$1 = [];
/**
 * 'e-step' directive represents a step of the Angular Stepper.
 * It must be contained in a Stepper component(`ejs-stepper`).
 * ```html
 * <ejs-stepper>
 *  <e-steps>
 *   <e-step [iconCss]='e-icons e-folder' [text]='Step 1' />
 *   <e-step [iconCss]='e-icons e-folder' [text]='Step 2' />
 *  </e-steps>
 * </ejs-stepper>
 * ```
 */
class StepDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
StepDirective.ɵfac = function StepDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StepDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
StepDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StepDirective,
  selectors: [["e-step"]],
  inputs: {
    cssClass: "cssClass",
    disabled: "disabled",
    iconCss: "iconCss",
    isValid: "isValid",
    label: "label",
    optional: "optional",
    status: "status",
    text: "text"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-stepper>e-steps>e-step',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Step Array Directive
 * @private
 */
class StepsDirective extends ArrayBase {
  constructor() {
    super('steps');
  }
}
StepsDirective.ɵfac = function StepsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StepsDirective)();
};
StepsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StepsDirective,
  selectors: [["e-steps"]],
  contentQueries: function StepsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, StepDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-stepper>e-steps',
      queries: {
        children: new ContentChildren(StepDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['activeStep', 'animation', 'cssClass', 'enablePersistence', 'enableRtl', 'labelPosition', 'linear', 'locale', 'orientation', 'readOnly', 'showTooltip', 'stepType', 'steps', 'template', 'tooltipTemplate'];
const outputs = ['beforeStepRender', 'created', 'stepChanged', 'stepChanging', 'stepClick', 'activeStepChange'];
const twoWays = ['activeStep'];
/**
 * Represents the EJ2 Angular Stepper Component.
 * ```html
 * <nav ejs-stepper [steps]='stepItems'></nav>
 * ```
 */
let StepperComponent = class StepperComponent extends Stepper {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['steps'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childSteps;
    this.containerContext.ngAfterContentChecked(this);
  }
};
StepperComponent.ɵfac = function StepperComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StepperComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
StepperComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: StepperComponent,
  selectors: [["ejs-stepper"]],
  contentQueries: function StepperComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
      i0.ɵɵcontentQuery(dirIndex, _c15, 5);
      i0.ɵɵcontentQuery(dirIndex, StepsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childSteps = _t.first);
    }
  },
  inputs: {
    activeStep: "activeStep",
    animation: "animation",
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    labelPosition: "labelPosition",
    linear: "linear",
    locale: "locale",
    orientation: "orientation",
    readOnly: "readOnly",
    showTooltip: "showTooltip",
    stepType: "stepType",
    steps: "steps",
    template: "template",
    tooltipTemplate: "tooltipTemplate"
  },
  outputs: {
    beforeStepRender: "beforeStepRender",
    created: "created",
    stepChanged: "stepChanged",
    stepChanging: "stepChanging",
    stepClick: "stepClick",
    activeStepChange: "activeStepChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c17,
  decls: 1,
  vars: 0,
  template: function StepperComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c16);
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], StepperComponent.prototype, "template", void 0);
__decorate([Template()], StepperComponent.prototype, "tooltipTemplate", void 0);
StepperComponent = __decorate([ComponentMixins([ComponentBase])], StepperComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-stepper',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content select='nav'></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childSteps: new ContentChild(StepsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    tooltipTemplate: [{
      type: ContentChild,
      args: ['tooltipTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Stepper component.
 */
class StepperModule {}
StepperModule.ɵfac = function StepperModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StepperModule)();
};
StepperModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StepperModule
});
StepperModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [StepperComponent, StepDirective, StepsDirective],
      exports: [StepperComponent, StepDirective, StepsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Stepper component with providers.
 */
class StepperAllModule {}
StepperAllModule.ɵfac = function StepperAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StepperAllModule)();
};
StepperAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StepperAllModule
});
StepperAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, StepperModule], StepperModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, StepperModule],
      exports: [StepperModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AccordionAllModule, AccordionComponent, AccordionItemDirective, AccordionItemsDirective, AccordionModule, AppBarAllModule, AppBarComponent, AppBarModule, BreadcrumbAllModule, BreadcrumbComponent, BreadcrumbItemDirective, BreadcrumbItemsDirective, BreadcrumbModule, CarouselAllModule, CarouselComponent, CarouselItemDirective, CarouselItemsDirective, CarouselModule, ContextMenuAllModule, ContextMenuComponent, ContextMenuModule, ItemDirective, ItemsDirective, MenuAllModule, MenuComponent, MenuItemDirective, MenuItemsDirective, MenuModule, SidebarAllModule, SidebarComponent, SidebarModule, StepDirective, StepperAllModule, StepperComponent, StepperModule, StepsDirective, TabAllModule, TabComponent, TabItemDirective, TabItemsDirective, TabModule, ToolbarAllModule, ToolbarComponent, ToolbarModule, TreeViewAllModule, TreeViewComponent, TreeViewModule };
