import { Component, input, output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { ToastComponent } from '../shared/toast.component';
import { UsersService } from './users.service';
import { SpinnerComponent } from '../shared/spinner.component';

@Component({
  selector: 'eaa-reset-user-twofa-dialog',
  imports: [DialogModule, ReactiveFormsModule, SpinnerComponent, ToastComponent],
  template: `
    <ejs-dialog
      #dgReset2Fa
      [showCloseIcon]="!processing"
      [isModal]="true"
      [visible]="false"
      width="800px"
      [closeOnEscape]="!processing"
    >
      <ng-template #content>
        <div class="alert alert-warning" role="alert">
          Please verify the legitimacy of the request with the client before proceeding with the reset.
        </div>
        <p>
          Are you sure you want to reset the 2FA device registrations of {{ userDisplayName() }} ({{ userEmail() }})?
        </p>
      </ng-template>
      <ng-template #header>Reset 2FA device registrations of {{ userDisplayName() }}</ng-template>
      <ng-template #footerTemplate>
        <div class="btn-group">
          <button class="btn btn-outline-secondary" (click)="close()" [disabled]="processing">CANCEL</button>
          <button class="btn btn-primary" (click)="reset2FaDeviceRegistrations()" [disabled]="processing">RESET</button>
        </div>
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastReset2FaAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <eaa-spinner />
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class ResetUserTwofaDialogComponent {
  public deviceRegistrationIds = input<string[] | undefined>([]);
  public userDisplayName = input<string | undefined>('');
  public userEmail = input<string | undefined>('');
  public userId = input<string | undefined>('');
  public processingMessage = '';
  public resetComplete = output<void>();
  public processing = false;

  @ViewChild('dgReset2Fa')
  public dialog: DialogComponent | null = null;

  @ViewChild('toastReset2FaAction')
  public toastReset2FaAction?: ToastComponent;

  constructor(private usersService: UsersService) {}

  public show(): void {
    this.dialog?.show();
  }

  public close(): void {
    this.dialog?.hide();
  }

  public reset2FaDeviceRegistrations() {
    if (this.userId() === undefined || this.deviceRegistrationIds() === undefined) {
      return;
    }

    this.processing = true;
    this.processingMessage = 'Resetting 2FA device registrations...';
    this.toastReset2FaAction?.show();

    this.usersService.resetUser2FaDeviceRegistrations$(this.userId()!, this.deviceRegistrationIds()!).subscribe({
      next: () => this.handleAfterExecute(true),
      error: () => this.handleAfterExecute(false)
    });
  }

  private handleAfterExecute(success: boolean): void {
    if (success) {
      this.processingMessage = `2FA device registrations reset successfully.`;
      this.resetComplete.emit();

      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.processing = false;
    this.dialog?.hide();
  }

  private resetToast() {
    this.toastReset2FaAction?.hide();
    this.processingMessage = '';
  }
}
