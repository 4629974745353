import { ItemModel } from '@syncfusion/ej2-angular-navigations';

export const FEEDBACK_FORM = 'https://forms.office.com/e/Xadt2ervr7';
export const USER_MANUAL = 'https://documentation.cigp.app/books/e-platform-admin';

export const CIGP_COLORS = [
  '#56725A',
  '#5A7865',
  '#5F7E71',
  '#63837D',
  '#678789',
  '#6C858E',
  '#708294',
  '#757F99',
  '#797B9F',
  '#847EA5',
  '#9282AA',
  '#A086B0',
  '#AE8BB5',
  '#BB8FB8',
  '#C094B3',
  '#C698AE',
  '#CB9DA9',
  '#D1A1A3',
  '#D6AFA6',
  '#DCBFAB',
  '#E1CFAF'
];

export const ROLE_AUDIT_TRAIL_MONTHLY_STATEMENTS_DISTRIBUTION_READ = 'AuditTrailMonthlyStatementsDistribution.Read';
export const ROLE_COMPLIANCE = 'Compliance';

export const CACHE_DURATION_HOURS = 4;

export const STORE_ACCOUNTS = 'accounts';
export const STORE_STAFFS = 'staffs';
export const STORE_USERS = 'users';

export const ID_PREFIX_CIGP = 'cigp-';

export const ID_EDIT_MAPPINGS = 'editMappings';
export const TOOLBAR_ITEM_MAPPINGS: ItemModel = {
  text: 'Mappings',
  tooltipText: 'Edit Mappings',
  prefixIcon: 'e-transform',
  id: ID_EDIT_MAPPINGS,
  disabled: true
};

export const ID_GO_TO_MESSAGES = 'goToMessages';
export const TOOLBAR_ITEM_MESSAGES: ItemModel = {
  text: 'Messages',
  tooltipText: 'Messages',
  prefixIcon: 'e-rename',
  id: ID_GO_TO_MESSAGES,
  disabled: true
};

export const ID_GO_TO_DOCUMENTS = 'goToDocuments';
export const TOOLBAR_ITEM_DOCUMENTS: ItemModel = {
  text: 'Documents',
  tooltipText: 'Documents',
  prefixIcon: 'e-folder-open',
  id: ID_GO_TO_DOCUMENTS,
  disabled: true
};

export const ID_SEND_EMAIL = 'sendEmail';
export const TOOLBAR_ITEM_SEND_EMAIL: ItemModel = {
  text: 'Send Email',
  tooltipText: 'Send by email',
  prefixIcon: 'e-send',
  id: ID_SEND_EMAIL,
  disabled: true
};

export const ID_RESET_2FA = 'reset2fa';
export const TOOLBAR_ITEM_RESET_2FA: ItemModel = {
  text: 'Reset 2FA',
  tooltipText: 'Reset 2FA device registrations',
  prefixIcon: 'e-unlock',
  id: ID_RESET_2FA,
  disabled: true
};

export const USER_TYPE_CLIENT = 'client';
export const USER_TYPE_STAFF = 'staff';
