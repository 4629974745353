import { NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'eaa-spinner',
  imports: [NgStyle],
  template: `
    <div [class]="class" [ngStyle]="{ height: diameterStr, width: diameterStr }">
      <span class="visually-hidden">Loading...</span>
    </div>
  `
})
export class SpinnerComponent {
  public diameter = input(20);
  public style = input('primary');

  public get diameterStr(): string {
    return `${this.diameter()}px`;
  }

  public get class(): string {
    return `spinner-border text-${this.style()}`;
  }
}
